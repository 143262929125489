<div class="api-keys" *ngIf="!newUI">
  <loader
    size="md"
    *ngIf="viewState.isLoading"
    [loading]="viewState.isLoading"
    class="loader"
  ></loader>
  <div class="api-keys__right" *ngIf="!viewState.isLoading">
    <p class="caption1--regular content-medium-color">API Key</p>
    <div class="api-keys__right__key">
      <p class="body2--bold content-main-color">{{ viewState.apiKey }}</p>
      <img
        class="clickable"
        src="assets/img/stores/copy.svg"
        (click)="
      presenter.emitViewEvent({
        type: 'ClickCopyKey',
      })
    "
      />
    </div>
    <p class="caption2--regular content-medium-color">
      {{ 'STORES.API.EXPIRES_AT' | translate }} {{ viewState.expiresAt }}
    </p>
  </div>
  <div class="api-keys__left">
    <p class="caption1--regular content-medium-color">{{ 'STORES.API.QUESTION' | translate }}</p>
    <p class="caption1--medium content-main-color">{{ 'STORES.API.ANSWER' | translate }}</p>
  </div>
</div>

<div class="new-api-keys" *ngIf="newUI">
  <loader
    size="md"
    *ngIf="viewState.isLoading"
    [loading]="viewState.isLoading"
    class="loader"
  ></loader>

  <div class="new-api-keys__right" *ngIf="!viewState.isLoading">
    <p class="caption1--medium content-medium-color">
      {{ 'STORES.API_KEY' | translate }}
    </p>

    <p class="caption1--regular content-main-color">{{ 'STORES.API.ANSWER' | translate }}</p>

    <div class="new-api-keys__right__key">
      <p class="body2--bold content-main-color">{{ viewState.apiKey }}</p>
      <img
        class="clickable"
        src="assets/img/stores/copy.svg"
        (click)="
      presenter.emitViewEvent({
        type: 'ClickCopyKey',
      })
    "
      />
    </div>

    <p class="caption2--regular expires__at content-medium-color">
      {{ 'STORES.API.EXPIRES_AT' | translate }} {{ viewState.expiresAt }}
    </p>
  </div>
</div>
